<template>
    <Layout ref="layout">
        <div class="head-enterprise-page pl-4 mb-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="box-plan-storage mb-3">
                        <div v-if="currentPlan != null" class="d-flex justify-content-between mb-3">
                            <span class="label-settings-name">{{ capitalizeFirstLetter(currentPlan.package.name_package) }}</span>
                            <span v-if="isApplePay == false" class="amount-text-setting-v3">${{ currentPlan.price.recurring.interval == 'month' ? currentPlan.package.price_month
                                : currentPlan.package.price_year }}/{{ currentPlan.price.recurring.interval }}</span>
                            <span v-if="isApplePay == true" class="amount-text-setting-v3">${{ currentPlan.product_id == currentPlan.package.apple_id_year ? currentPlan.package.price_year
                                : currentPlan.package.price_year }}/{{ currentPlan.product_id == currentPlan.package.apple_id_year ? 'year' : 'month' }}</span>
                        </div>
                        <div v-else class="d-flex justify-content-between mb-3">
                            <span class="label-settings-name">Basic</span>
                            <span class="amount-text-setting-v3">Free</span>
                        </div>
                        <div>
                            <b-progress :value="originalUsed" :max="originalSize"></b-progress>
                        </div>
                        <span class="plan-settings-text margin-11">{{used}} of {{size}} used.</span>
                        <span v-if="currentPlan != null" class="plan-settings-text margin-11 mb-3">{{ workgroupsList.length }} of {{ currentPlan.package.workgroup.max_workgroup }} workgroups created</span>
                        <span v-else class="plan-settings-text margin-11 mb-3">{{ workgroupsList.length }} of 5 workgroups created</span>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn-settings-upgrade-packages" @click="getPackages()">Upgrade</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box-plan-storage mb-3">
                        <p class="label-settings-name">Payment Method</p>
                        <div class="box-payment-v3" v-if="isPaid && isApplePay == false">
                            <img src="@/assets/images/icon/visa-logo.svg" alt="">
                            <span class="label-settings-name mx-3">{{ cardNumber }}</span>
                            <span class="cc-date">{{ cardExpireDate }}</span>
                        </div>
                        <div class="box-payment-v3" v-if="isApplePay == true">
                            <img src="@/assets/images/icon/Apple-pay-logo.svg" alt="">
                            <span class="label-settings-name mx-3">Apple Payment</span>
                        </div>
                        <div v-if="!isPaid && isApplePay == false">
                            <span class="cc-date">You’re not set your payment method yet.</span>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="button" :disabled="!isPaid || isApplePay == true" class="btn-payment-method-v3" @click="setPayment()">Manage</button>
                        </div>
                    </div>
                </div>
            </div>
            <span class="label-settings-name">Billing and invoicing</span>
        </div>
        <div class="list-enterprise-member px-4">
            <table class="table-enterprise">
                <thead>
                    <th class="history-payment-head">Date</th>
                    <th class="history-payment-head">Plan</th>
                    <th class="history-payment-head">Status</th>
                    <th class="history-payment-head">Amount</th>
                    <th class="history-payment-head">Invoice</th>
                </thead>
                <tbody v-if="listPaymentHistory.length > 0">
                    <tr v-for="(history, index) in listPaymentHistory" :key="index">
                        <td class="history-payment-head">
                            <div class="d-flex align-items-center">
                                <span class="enterprise-member-list-text">
                                {{ convertDate(history.start_from) }}
                                </span>
                            </div>
                        </td>
                        <td class="history-payment-head">
                            <span class="enterprise-member-list-text">
                                {{ history.package ? capitalizeFirstLetter(history.package.name_package) : '' }}
                            </span>
                        </td>
                        <td class="history-payment-head">
                            <span
                                :class="history.status.toLowerCase() == 'paid' || history.status.toLowerCase() == 'purchased' ? 'green-text-status'
                                : history.status.toLowerCase() == 'rejected' ? 'red-text-status'
                                : 'black-text-status'"
                            >
                                {{capitalizeFirstLetter(history.status)}}
                            </span>
                        </td>
                        <td class="history-payment-head">
                            <span v-if="isApplePay == false" class="enterprise-member-list-text">
                                ${{ listPaymentHistory[0].interval == 'month' ? listPaymentHistory[0].package.price_month
                                : listPaymentHistory[0].package.price_year }}/{{ listPaymentHistory[0].interval }}
                            </span>
                            <span v-if="isApplePay == true" class="enterprise-member-list-text">
                                ${{ listPaymentHistory[0].product_id == listPaymentHistory[0].package.apple_id_year ? listPaymentHistory[0].package.price_year
                                : listPaymentHistory[0].package.price_year }}/{{ listPaymentHistory[0].product_id == listPaymentHistory[0].package.apple_id_year ? 'year' : 'month' }}
                            </span>
                        </td>
                        <td class="history-payment-head">
                            <a :href="history.invoice_pdf" class="enterprise-member-list-text-invoice">
                                Download
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="listPaymentHistory.length <= 0" class="d-flex justify-content-center align-items-center flex-column mt-3">
                <span class="mb-1 empty-word-title">No payment history</span>
                <span class="empty-word-sub-title">Payment history will be displayed here after you purchase your plan.</span>
            </div>
        </div>
        <div v-if="listPaymentHistory.length > 0" class="footer-payment-histories">
            <span class="">NOTE: We only show up 1 year of payment history.</span>
        </div>
        <!-- <pre> -->
            <!-- {{currentPlan}} -->
            <!-- {{listPaymentHistory}} -->
        <!-- </pre> -->
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import moment from 'moment'

export default {
    data() {
        return {
            isPaid: false
        }
    },
    components: {
        Layout
    },
    beforeMount() {
        this.$store.dispatch('packages/getActivePlan')
        this.$store.dispatch('user/getLimit')
        .then((res) => {
            if(res[0].stripe_id){
                this.isPaid = true
            } else {
                this.isPaid = false
            }
        })
    },
    computed: {
        currentPlan(){
            return this.$store.getters['packages/activePlan']
        },
        isApplePay() {
            return this.$store.getters["user/isApplePay"]
        },
        originalSize(){
            return this.$store.getters['user/size_directory'];
        },
        originalUsed(){
            return this.$store.getters['user/total_used'];
        },
        size(){
            return this.$store.getters['user/total_directory_string']
        },
        used(){
            return this.$store.getters['user/used_directory_string']
        },
        workgroupsList(){
            return this.$store.getters['workgroup/workgroups']
        },
        listPaymentHistory () {
            return this.$store.getters["packages/paymentHistory"]
        },
        payMethod() {
            return this.$store.getters["user/card_payment"]
        },
        cardNumber() {
            var data = this.$store.getters["user/card_payment"]
            return ` •••• •••• •••• ${data.last4}`
        },
        cardExpireDate() {
            var data = this.$store.getters["user/card_payment"]
            var month = data.exp_month
            var year = data.exp_year.toString().substr(-2)
            return `${month}/${year}`
        }
    },
    methods: {
        setPayment() {
            this.$store.dispatch('packages/getPaymentMethod')
        },
        getPackages(){
            if(this.isApplePay == false){
                this.$bvModal.show('modalPackages')
            } else {
                this.$bvModal.show('modal-apple-pay')
            }
        },
        convertDate(timestamp) {
            return moment(timestamp * 1000).format('MMM DD, YYYY')
        },
        capitalizeFirstLetter(str){
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        },
        niceBytes(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
        }
    }
}
</script>

<style>

</style>